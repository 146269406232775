var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sb-page-header',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('h1',[_vm._v("Instellingen")])]},proxy:true},{key:"right",fn:function(){return undefined},proxy:true}])}),[_c('i-form',{ref:"school",attrs:{"rules":_vm.validationRules,"label-width":160}},[_c('sb-divider',{attrs:{"title":"School seats"}}),(
          (_vm.data && !_vm.data.parentOrganisation) ||
          (_vm.data.parentOrganisation && !_vm.data.parentOrganisation.invoiceSelf)
        )?_c('sb-change-seats',{attrs:{"organisation-id":_vm.data.id,"initial-seats":_vm.data.seats}}):_vm._e(),(_vm.data.parentOrganisation && _vm.data.parentOrganisation.invoiceSelf)?_c('p',[_vm._v(" De seats zijn gedefinieerd in het bovenliggende schooldistrict ("),_c('router-link',{attrs:{"to":{
            name: 'ResourceManagementSchooldistrictsSchooldistrict',
            params: { organisationId: _vm.data.parentOrganisation.id },
          }}},[_vm._v(" "+_vm._s(_vm.data.parentOrganisation.name)+" ")]),_vm._v("). ")],1):_vm._e(),_c('sb-spacer',{attrs:{"height":"20"}}),_c('sb-divider',{attrs:{"title":"School status"}}),_c('sb-set-closing-date',{attrs:{"organisation-id":_vm.data.id,"initial-date":_vm.data.closingDate,"data":_vm.data},on:{"change":function($event){return _vm.$emit('refetch')}}}),_c('sb-divider',{attrs:{"title":"School deactiveren"}}),_c('form-item',{attrs:{"label":"Status aanpassen:"}},[_c('sb-activate-organisation',{attrs:{"id":_vm.data.id,"label":"School","active":_vm.data.active}})],1),(this.$user.context.isAdmin)?[_c('sb-divider',{attrs:{"title":"Bovenschoolse Organisatie"}}),_c('sb-spacer',{attrs:{"height":"20"}}),_c('sb-add-or-remove-from-resource',{attrs:{"original":_vm.selectedSchoolDistrict,"options":_vm.schoolDistricts,"child-id":_vm.data.id,"migration-selector":"schoolId","label":"Schooldistrict","slug":"schoolDistrict"}}),_c('sb-spacer',{attrs:{"height":"400"}})]:_vm._e()],2)],_c('sb-spacer',{attrs:{"height":"20"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }